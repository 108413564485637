<template>
  <div class="d-flex justify-space-between">
    <h1 class="">Criar Usuário</h1>
    <a class="text-decoration-none">
      <v-btn class="text-white" color="grey" href="/users">
        <v-icon> mdi-plus </v-icon>
        Cancelar
      </v-btn>
      <v-btn class="text-white" color="movida-orange" @click="store">
        <v-icon> mdi-plus </v-icon>
        Salvar
      </v-btn>
    </a>
  </div>
  <v-container>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field label="Nome" :rules="rules" hide-details="auto" v-model="name" required="true"></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field label="Email" hide-details="auto" v-model="email" required="true"></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field label="Senha" hide-details="auto" type="password" v-model="password" required="true"></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-select v-model="profile" :items="profiles" item-title="name" item-value="id" label="Perfís" persistent-hint return-object single-line required="true"></v-select>
      </v-col>
    </v-row>
  </v-container>
  <v-snackbar v-model="snackbar" :timeout="timeout">
    <v-icon class="ml-1"> mdi-information-outline </v-icon> {{ text }}
    <template v-slot:actions>
      <v-btn variant="text" @click="snackbar = false"> Fechar </v-btn>
    </template>
  </v-snackbar>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import * as services from "@/services";
import { UserStoreRequest } from "@/models/user/UserStoreRequest";

@Options({
  components: {},
})
export default class ModuleCreateView extends Vue {
  public rules = [(value: any) => !!value || "Required."];
  public name = "";
  public email = "";
  public profile_id = 1;
  public password = "";
  public snackbar = false;
  public text = "Alterações salvas com sucesso!";
  public timeout = 2000;
  public profiles = [{}];
  public profile = { name: "", id: 0 };

  mounted(): void {
    this.getProfiles();
  }

  async store(event: any): Promise<void> {
    const storeRequest: UserStoreRequest = {
      name: this.name,
      email: this.email,
      profile_id: this.profile.id,
    };
    const response = await services.UserService.store(storeRequest);
    this.snackbar = true;
  }

  async getProfiles() {
    const response = await services.ProfileService.all();
    this.profiles = response.results;
  }
}
</script>
<style lang="css">
.default-data-base {
  border: solid #efecec 0.4px;
}

.w-3 {
  width: 10px;
}

.h-3 {
  height: 10px;
}
</style>
