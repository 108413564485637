<template>
  <div class="d-flex justify-space-between">
    <h1 class="">Agendamentos</h1>
  </div>
  <v-row class="mb-3 flex justify-between">
    <v-col md="2" sm="2">
      <v-text-field v-model="search.order" clearable density="compact" hide-details label="Nª do pedido" prepend-inner-icon="mdi-magnify" single-line variant="solo" @input="searchByNeedle" @click:clear="clearFilter"></v-text-field>
    </v-col>
    <v-col md="2" sm="2">
      <v-text-field v-model="search.cpf" clearable density="compact" hide-details label="CPF" prepend-inner-icon="mdi-magnify" single-line variant="solo" @input="searchByNeedle" @click:clear="clearFilter"></v-text-field>
    </v-col>
    <v-col md="2" sm="2">
      <v-autocomplete v-model="search.plate" label="Placa" :clearable="true" :items="allFleets" item-title="Placa" item-value="Placa" variant="solo" density="compact" multiple @update:modelValue="searchByNeedle"></v-autocomplete>
    </v-col>
    <v-col md="2" sm="2">
      <v-autocomplete v-model="search.branch" label="Loja" :clearable="true" :items="allBranch" item-title="Loja" item-value="FilialID" variant="solo" density="compact" multiple @update:modelValue="searchByNeedle"></v-autocomplete>
    </v-col>
    <v-col md="2" sm="2">
      <VueDatePicker v-model="dateSearch" range vertical :format="format" locale="pt-br" select-text="selecionar" cancel-text="cancelar" :enable-time-picker="false" @closed="searchByNeedle" @range-start="onRangeStart" @range-end="onRangeEnd" @cleared="clearCalendar"></VueDatePicker>
    </v-col>
    <v-col md="2" sm="2">
      <v-btn class="text-white float-right" color="movida-orange" @click="newSchedule"> Novo agendamento </v-btn>
    </v-col>
  </v-row>
  <v-data-table-server v-model:items-per-page="itemsPerPage" :headers="headers" :items="items" :loading="isLoadingTable" class="elevation-1" hide-default-footer item-value="name" to="asd">
    <template v-slot:bottom></template>
    <template v-slot:[`item.name`]="{ item }">
      <tr class="clickable" @click="goToSchedule(item.props.title.id, item.props.title.client.id, item.props.title.canceled)">
        <v-row>
          <p>{{ item.props.title.order.id }} - {{ item.props.title.client.name }}</p>
        </v-row>
      </tr>
    </template>
    <template v-slot:[`item.cpf`]="{ item }">
      <tr class="clickable" @click="goToSchedule(item.props.title.id, item.props.title.client.id, item.props.title.canceled)">
        <v-row>
          <p>{{ item.props.title?.client?.cpf }}</p>
        </v-row>
      </tr>
    </template>
    <template v-slot:[`item.plate`]="{ item }">
      <tr class="clickable" @click="goToSchedule(item.props.title.id, item.props.title.client.id, item.props.title.canceled)">
        <v-row>
          <p>{{ item.props.title?.fleet?.plate }}</p>
        </v-row>
      </tr>
    </template>
    <template v-slot:[`item.description`]="{ item }">
      <tr class="clickable" @click="goToSchedule(item.props.title.id, item.props.title.client.id, item.props.title.canceled)">
        <v-row>
          <p>{{ item.props.title.branch.store }}</p>
        </v-row>
      </tr>
    </template>
    <template v-slot:[`item.datetime`]="{ item }">
      <tr class="clickable" @click="goToSchedule(item.props.title.id, item.props.title.client.id, item.props.title.canceled)">
        <v-row>
          <p>{{ format_date(item.props.title.scheduling_at) }}</p>
        </v-row>
      </tr>
    </template>
    <template v-slot:[`item.scheduleBy`]="{ item }">
      <tr class="clickable" @click="goToSchedule(item.props.title.id, item.props.title.client.id, item.props.title.canceled)">
        <v-row>
          <p>{{ item.props.title?.scheduling_by?.name }}</p>
        </v-row>
      </tr>
    </template>
    <template v-slot:[`item.createdAt`]="{ item }">
      <tr class="clickable" @click="goToSchedule(item.props.title.id, item.props.title.client.id, item.props.title.canceled)">
        <v-row>
          <p>{{ format_date(item.props.title.create_at) }}</p>
        </v-row>
      </tr>
    </template>
    <!-- <template v-slot:[`item.options`]="{ item }">
      <v-menu bottom left v-if="!item.props.title.canceled">
        <template v-slot:activator="{ props }">
          <v-btn dark icon style="box-shadow: none" v-bind="props">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="send(item.props.title.id)">
            <v-list-item-title>Enviar comunicado ...</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title class="clickable" style="color: red" @click="destroy(item.props.title.id, item.props.title.client.id)">Deletar</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template> -->
  </v-data-table-server>
  <v-row class="mt-2 d-flex align-center pb-10" justify="center">
    <div class="text-center">
      <v-pagination v-model="currentPage" :length="numberOfPages" :total-visible="7" color="movida-orange" @next="nextPage" @prev="prevPage" @update:modelValue="loadPage"></v-pagination>
      <div> {{ pageFrom }} a {{ pageTo }} de {{ totalOfRows }} resultados</div>
    </div>
  </v-row>
  <v-snackbar v-model="snackbar" :timeout="timeout">
    <v-icon class="ml-1"> mdi-information-outline </v-icon> {{ text }}
    <template v-slot:actions>
      <v-btn variant="text" @click="closeSnack"> Fechar </v-btn>
    </template>
  </v-snackbar>
</template>
<script lang="ts">
import { ref, toRaw } from "vue";
import { Options, Vue } from "vue-class-component";
import * as services from "@/services";
import { Schedule } from "@/models/schedule/Schedule";
import moment from "moment";
import VueDatePicker from "@vuepic/vue-datepicker";
import messages from "@/constants/messages";
import "@vuepic/vue-datepicker/dist/main.css";

@Options({
  components: {
    VueDatePicker,
  },
})
export default class ScheduleIndexView extends Vue {
  public items: Array<Schedule> = [];
  public module_channels: any;
  public currentPage = 0;
  public numberOfPages = 0;
  public itemsPerPage = 0;
  public messages = {};
  public totalOfRows = 0;
  public pageFrom = 0;
  public pageTo = 1;
  public headers = [
    { title: "Pedido", align: "start", key: "name", sortable: false },
    { title: "CPF", align: "start", key: "cpf", sortable: false },
    { title: "Placa", align: "start", key: "plate", sortable: false },
    { title: "Loja", align: "start", key: "description", sortable: false },
    { title: "Data e Hora", align: "start", key: "datetime", sortable: false },
    { title: "Agendado por", align: "start", key: "scheduleBy", sortable: false },
    { title: "Criado em", align: "start", key: "createdAt", sortable: false },
    // { title: "Ações", align: "end", key: "options", sortable: false },foi solicitado comentar essa funcionalidade
  ];
  public headersOrdenar = [
    { title: "Pedido", align: "start", key: "name", sortable: false },
    { title: "Loja", align: "start", key: "description", sortable: false },
  ];
  public sortSelected = "name";
  public isLoadingTable = true;
  public orderSearch = null;
  public dateSearch = ref();
  public fromSearch: any = null;
  public toSearch: any = null;
  public branchSearch: any = null;
  public allBranch: Array<any> = [];
  public allPeople: Array<any> = [];
  public allFleets: Array<any> = [];
  public snackbar = false;
  public text = "";
  public timeout = 10000;
  public search = {
    order: null,
    people: null,
    plate: null,
    branch: null,
  };

  mounted(): void {
    this.module_channels = this.getFromStorage("MODULE_CHANNEL");
    this.willMountDataPage();
    this.getFilters();
    this.messages = messages;
  }

  async willMountDataPage(): Promise<void> {
    const { currentPage } = this;
    const response = await services.ScheduleService.getAllSchedule(this.module_channels, currentPage);

    this.unMountRequest(response);

    this.changeLoading();
  }

  nextPage(): void {
    this.changeLoading();
    this.getScheduleList(this.currentPage, this.search, this.fromSearch, this.toSearch, this.sortSelected);
  }

  prevPage(): void {
    this.changeLoading();
    this.getScheduleList(this.currentPage, this.search, this.fromSearch, this.toSearch, this.sortSelected);
  }

  loadPage(value: number): void {
    this.getScheduleList(value, this.search, this.fromSearch, this.toSearch, this.sortSelected);
    this.changeLoading();
  }

  changeLoading(): void {
    this.isLoadingTable = !this.isLoadingTable;
  }

  async getScheduleList(page: number, search: any = {}, from: string | null = null, to: string | null = null, sortColumn: string | null = null) {
    try {
      if (this.isNullableSort(sortColumn)) {
        this.resetSortSelected();
        sortColumn = this.sortSelected;
      }
      const response = await services.ScheduleService.getAllSchedule(this.module_channels, page, search, from, to, sortColumn);

      this.unMountRequest(response);
    } catch (e) {
      console.log("ERRO", e);
    }
    this.changeLoading();
  }

  async getAllBranch() {
    const response = await services.ScheduleService.getAllBranch();
    this.allBranch = response?.results;
  }

  async getFilters() {
    const response = await services.ScheduleService.getFilters();
    this.allBranch = response?.results?.branchs;
    this.allFleets = response?.results?.fleets;
  }

  async destroy(id: number, peopleId: number) {
    this.changeLoading();
    const response = await services.ScheduleService.destroy(id, peopleId);
    if (response.has_success) {
      this.text = messages.deletar;
      this.snackbar = true;
    }
    this.willMountDataPage();
  }

  format(date: any) {
    return moment(String(date[0])).format("DD/MM/YYYY") + " - " + moment(String(date[1])).format("DD/MM/YYYY");
  }

  getFromStorage(key: string): string | null {
    return localStorage.getItem(key);
  }

  goToSchedule(scheduleId: number, peopleId: number, skip: boolean) {
    if (skip) {
      return;
    }
    this.$router.push({ path: `/schedules/update/${scheduleId}`, query: { peopleId: peopleId } });
  }

  send(schedule: any) {
    return;
  }

  newSchedule() {
    this.$router.push({ path: "/schedules/store" });
  }

  searchByNeedle() {
    if (this.search.branch && !this.fromSearch && !this.toSearch) {
      this.text = "Por favor, selecione um periodo";
      this.snackbar = true;
      return;
    }

    this.changeLoading();
    this.items = [];
    this.getScheduleList(1, this.search, this.fromSearch, this.toSearch);
  }

  onRangeStart(value: any) {
    this.fromSearch = this.formatterDateSearch(value);
  }

  onRangeEnd(value: any) {
    this.toSearch = this.formatterDateSearch(value);
  }

  clearCalendar() {
    this.fromSearch = null;
    this.toSearch = null;

    this.searchByNeedle();
  }

  format_date(value: any) {
    if (value) {
      return moment(String(value)).format("DD/MM/YYYY HH:mm");
    }
  }

  private formatterDateSearch(value: any) {
    return moment(String(value)).format("YYYY-MM-DD");
  }

  public clearFilter() {
    this.changeLoading();
    this.items = [];
    this.currentPage = 1;
    this.getScheduleList(1);
  }

  changeSortSelected(itemSelected: string) {
    this.sortSelected = itemSelected;
    this.changeLoading();
    this.getScheduleList(this.currentPage, null, null, null, itemSelected);
  }

  closeSnack(): void {
    this.snackbar = false;
  }

  private isNullableSort(sort: string | null) {
    return sort === null;
  }

  private resetSortSelected() {
    this.loadSortSelected();
  }

  private loadSortSelected() {
    this.sortSelected = this.headers[0].key;
  }

  private unMountRequest(response: any) {
    if (response.results) {
      this.currentPage = response.results.current_page;
      this.items = response.results.data as Array<Schedule>;
      this.numberOfPages = response.results.last_page;
      this.itemsPerPage = response.results.per_page;
      this.totalOfRows = response.results.total;
      this.pageFrom = response.results.current_page;
      this.pageTo = response.results.per_page;
    }
  }
}
</script>
<style lang="css">
.default-data-base {
  border: solid #efecec 0.4px;
}

.w-3 {
  width: 10px;
}

.h-3 {
  height: 10px;
}

.v-table .v-table__wrapper > table > tbody > tr:not(:last-child) > td {
  height: 58px;
}

.dp__input {
  box-shadow: 0px 3px 1px -2px var(--v-shadow-key-umbra-opacity, rgba(0, 0, 0, 0.2)), 0px 2px 2px 0px var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.14)), 0px 1px 5px 0px var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.12));
}

.dp__action_select,
.dp__range_end,
.dp__range_start,
.dp__active_date {
  background-color: #ff9800 !important;
}

.clickable {
  cursor: pointer;
}
</style>
