import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_autocomplete = _resolveComponent("v-autocomplete")!
  const _component_VueDatePicker = _resolveComponent("VueDatePicker")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_data_table_server = _resolveComponent("v-data-table-server")!
  const _component_v_pagination = _resolveComponent("v-pagination")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[12] || (_cache[12] = _createElementVNode("div", { class: "d-flex justify-space-between" }, [
      _createElementVNode("h1", { class: "" }, "Agendamentos")
    ], -1)),
    _createVNode(_component_v_row, { class: "mb-3 flex justify-between" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, {
          md: "2",
          sm: "2"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_text_field, {
              modelValue: _ctx.search.order,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search.order) = $event)),
              clearable: "",
              density: "compact",
              "hide-details": "",
              label: "Nª do pedido",
              "prepend-inner-icon": "mdi-magnify",
              "single-line": "",
              variant: "solo",
              onInput: _ctx.searchByNeedle,
              "onClick:clear": _ctx.clearFilter
            }, null, 8, ["modelValue", "onInput", "onClick:clear"])
          ]),
          _: 1
        }),
        _createVNode(_component_v_col, {
          md: "2",
          sm: "2"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_text_field, {
              modelValue: _ctx.search.cpf,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.search.cpf) = $event)),
              clearable: "",
              density: "compact",
              "hide-details": "",
              label: "CPF",
              "prepend-inner-icon": "mdi-magnify",
              "single-line": "",
              variant: "solo",
              onInput: _ctx.searchByNeedle,
              "onClick:clear": _ctx.clearFilter
            }, null, 8, ["modelValue", "onInput", "onClick:clear"])
          ]),
          _: 1
        }),
        _createVNode(_component_v_col, {
          md: "2",
          sm: "2"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_autocomplete, {
              modelValue: _ctx.search.plate,
              "onUpdate:modelValue": [
                _cache[2] || (_cache[2] = ($event: any) => ((_ctx.search.plate) = $event)),
                _ctx.searchByNeedle
              ],
              label: "Placa",
              clearable: true,
              items: _ctx.allFleets,
              "item-title": "Placa",
              "item-value": "Placa",
              variant: "solo",
              density: "compact",
              multiple: ""
            }, null, 8, ["modelValue", "items", "onUpdate:modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_v_col, {
          md: "2",
          sm: "2"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_autocomplete, {
              modelValue: _ctx.search.branch,
              "onUpdate:modelValue": [
                _cache[3] || (_cache[3] = ($event: any) => ((_ctx.search.branch) = $event)),
                _ctx.searchByNeedle
              ],
              label: "Loja",
              clearable: true,
              items: _ctx.allBranch,
              "item-title": "Loja",
              "item-value": "FilialID",
              variant: "solo",
              density: "compact",
              multiple: ""
            }, null, 8, ["modelValue", "items", "onUpdate:modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_v_col, {
          md: "2",
          sm: "2"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_VueDatePicker, {
              modelValue: _ctx.dateSearch,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.dateSearch) = $event)),
              range: "",
              vertical: "",
              format: _ctx.format,
              locale: "pt-br",
              "select-text": "selecionar",
              "cancel-text": "cancelar",
              "enable-time-picker": false,
              onClosed: _ctx.searchByNeedle,
              onRangeStart: _ctx.onRangeStart,
              onRangeEnd: _ctx.onRangeEnd,
              onCleared: _ctx.clearCalendar
            }, null, 8, ["modelValue", "format", "onClosed", "onRangeStart", "onRangeEnd", "onCleared"])
          ]),
          _: 1
        }),
        _createVNode(_component_v_col, {
          md: "2",
          sm: "2"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_btn, {
              class: "text-white float-right",
              color: "movida-orange",
              onClick: _ctx.newSchedule
            }, {
              default: _withCtx(() => _cache[8] || (_cache[8] = [
                _createTextVNode(" Novo agendamento ")
              ])),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_v_data_table_server, {
      "items-per-page": _ctx.itemsPerPage,
      "onUpdate:itemsPerPage": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.itemsPerPage) = $event)),
      headers: _ctx.headers,
      items: _ctx.items,
      loading: _ctx.isLoadingTable,
      class: "elevation-1",
      "hide-default-footer": "",
      "item-value": "name",
      to: "asd"
    }, {
      bottom: _withCtx(() => _cache[9] || (_cache[9] = [])),
      [`item.name`]: _withCtx(({ item }) => [
        _createElementVNode("tr", {
          class: "clickable",
          onClick: ($event: any) => (_ctx.goToSchedule(item.props.title.id, item.props.title.client.id, item.props.title.canceled))
        }, [
          _createVNode(_component_v_row, null, {
            default: _withCtx(() => [
              _createElementVNode("p", null, _toDisplayString(item.props.title.order.id) + " - " + _toDisplayString(item.props.title.client.name), 1)
            ]),
            _: 2
          }, 1024)
        ], 8, _hoisted_1)
      ]),
      [`item.cpf`]: _withCtx(({ item }) => [
        _createElementVNode("tr", {
          class: "clickable",
          onClick: ($event: any) => (_ctx.goToSchedule(item.props.title.id, item.props.title.client.id, item.props.title.canceled))
        }, [
          _createVNode(_component_v_row, null, {
            default: _withCtx(() => [
              _createElementVNode("p", null, _toDisplayString(item.props.title?.client?.cpf), 1)
            ]),
            _: 2
          }, 1024)
        ], 8, _hoisted_2)
      ]),
      [`item.plate`]: _withCtx(({ item }) => [
        _createElementVNode("tr", {
          class: "clickable",
          onClick: ($event: any) => (_ctx.goToSchedule(item.props.title.id, item.props.title.client.id, item.props.title.canceled))
        }, [
          _createVNode(_component_v_row, null, {
            default: _withCtx(() => [
              _createElementVNode("p", null, _toDisplayString(item.props.title?.fleet?.plate), 1)
            ]),
            _: 2
          }, 1024)
        ], 8, _hoisted_3)
      ]),
      [`item.description`]: _withCtx(({ item }) => [
        _createElementVNode("tr", {
          class: "clickable",
          onClick: ($event: any) => (_ctx.goToSchedule(item.props.title.id, item.props.title.client.id, item.props.title.canceled))
        }, [
          _createVNode(_component_v_row, null, {
            default: _withCtx(() => [
              _createElementVNode("p", null, _toDisplayString(item.props.title.branch.store), 1)
            ]),
            _: 2
          }, 1024)
        ], 8, _hoisted_4)
      ]),
      [`item.datetime`]: _withCtx(({ item }) => [
        _createElementVNode("tr", {
          class: "clickable",
          onClick: ($event: any) => (_ctx.goToSchedule(item.props.title.id, item.props.title.client.id, item.props.title.canceled))
        }, [
          _createVNode(_component_v_row, null, {
            default: _withCtx(() => [
              _createElementVNode("p", null, _toDisplayString(_ctx.format_date(item.props.title.scheduling_at)), 1)
            ]),
            _: 2
          }, 1024)
        ], 8, _hoisted_5)
      ]),
      [`item.scheduleBy`]: _withCtx(({ item }) => [
        _createElementVNode("tr", {
          class: "clickable",
          onClick: ($event: any) => (_ctx.goToSchedule(item.props.title.id, item.props.title.client.id, item.props.title.canceled))
        }, [
          _createVNode(_component_v_row, null, {
            default: _withCtx(() => [
              _createElementVNode("p", null, _toDisplayString(item.props.title?.scheduling_by?.name), 1)
            ]),
            _: 2
          }, 1024)
        ], 8, _hoisted_6)
      ]),
      [`item.createdAt`]: _withCtx(({ item }) => [
        _createElementVNode("tr", {
          class: "clickable",
          onClick: ($event: any) => (_ctx.goToSchedule(item.props.title.id, item.props.title.client.id, item.props.title.canceled))
        }, [
          _createVNode(_component_v_row, null, {
            default: _withCtx(() => [
              _createElementVNode("p", null, _toDisplayString(_ctx.format_date(item.props.title.create_at)), 1)
            ]),
            _: 2
          }, 1024)
        ], 8, _hoisted_7)
      ]),
      _: 2
    }, 1032, ["items-per-page", "headers", "items", "loading"]),
    _createVNode(_component_v_row, {
      class: "mt-2 d-flex align-center pb-10",
      justify: "center"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_v_pagination, {
            modelValue: _ctx.currentPage,
            "onUpdate:modelValue": [
              _cache[6] || (_cache[6] = ($event: any) => ((_ctx.currentPage) = $event)),
              _ctx.loadPage
            ],
            length: _ctx.numberOfPages,
            "total-visible": 7,
            color: "movida-orange",
            onNext: _ctx.nextPage,
            onPrev: _ctx.prevPage
          }, null, 8, ["modelValue", "length", "onNext", "onPrev", "onUpdate:modelValue"]),
          _createElementVNode("div", null, _toDisplayString(_ctx.pageFrom) + " a " + _toDisplayString(_ctx.pageTo) + " de " + _toDisplayString(_ctx.totalOfRows) + " resultados", 1)
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_v_snackbar, {
      modelValue: _ctx.snackbar,
      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.snackbar) = $event)),
      timeout: _ctx.timeout
    }, {
      actions: _withCtx(() => [
        _createVNode(_component_v_btn, {
          variant: "text",
          onClick: _ctx.closeSnack
        }, {
          default: _withCtx(() => _cache[11] || (_cache[11] = [
            _createTextVNode(" Fechar ")
          ])),
          _: 1
        }, 8, ["onClick"])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_v_icon, { class: "ml-1" }, {
          default: _withCtx(() => _cache[10] || (_cache[10] = [
            _createTextVNode(" mdi-information-outline ")
          ])),
          _: 1
        }),
        _createTextVNode(" " + _toDisplayString(_ctx.text) + " ", 1)
      ]),
      _: 1
    }, 8, ["modelValue", "timeout"])
  ], 64))
}