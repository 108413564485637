import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_v_tabs = _resolveComponent("v-tabs")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_ScheduleIndexView = _resolveComponent("ScheduleIndexView")!
  const _component_v_window_item = _resolveComponent("v-window-item")!
  const _component_DeadlineIndexView = _resolveComponent("DeadlineIndexView")!
  const _component_v_window = _resolveComponent("v-window")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, { cols: "12" }, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [
            _createElementVNode("h1", { class: "text-h4" }, "Espera", -1)
          ])),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_v_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, { cols: "12" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_tabs, {
                      modelValue: _ctx.tab,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tab) = $event)),
                      onChange: _ctx.onTabChange
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_tab, { value: "schedule" }, {
                          default: _withCtx(() => _cache[3] || (_cache[3] = [
                            _createTextVNode("Agendamentos")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_v_tab, { value: "deadlines" }, {
                          default: _withCtx(() => _cache[4] || (_cache[4] = [
                            _createTextVNode("Prazo de Retirada")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["modelValue", "onChange"]),
                    _createVNode(_component_v_divider)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_window, {
                      modelValue: _ctx.tab,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.tab) = $event))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_window_item, { value: "schedule" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ScheduleIndexView)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_window_item, { value: "deadlines" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_DeadlineIndexView)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}