<template>
  <div class="d-flex justify-space-between">
    <h1 class="">Atualizar modulo</h1>
    <a class="text-decoration-none">
      <v-btn class="text-white" color="grey" href="/modules">
        <v-icon> mdi-plus </v-icon>
        Cancelar
      </v-btn>
      <v-btn class="text-white" color="movida-orange" @click="update" v-bind:disabled="btn_disabled">
        <v-icon> mdi-plus </v-icon>
        Salvar
      </v-btn>
    </a>
  </div>
  <div>
    <v-col cols="12" md="6">
      <v-text-field v-model="name" label="Nome" :rules="rules" hide-details="auto"></v-text-field>
    </v-col>
    <v-col cols="12" md="6">
      <v-text-field v-model="description" label="Descrição" :rules="rules" hide-details="auto"></v-text-field>
    </v-col>
    <v-col cols="12" md="6">
      <v-select v-model="channelsIds" :items="channels" label="Canais de Venda" multiple hint="escolha os canais de venda" persistent-hint item-title="name" item-value="id"></v-select>
    </v-col>
  </div>
  <v-snackbar v-model="snackbar" :timeout="timeout">
    <v-icon class="ml-1"> mdi-information-outline </v-icon> {{ text }}
    <template v-slot:actions>
      <v-btn variant="text" @click="closeSnack"> Fechar </v-btn>
    </template>
  </v-snackbar>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import * as services from "@/services";
import { Module } from "@/models/module/Module";
import { ModuleIndexWithPaginateResponse } from "@/models/module/ModuleIndexWithPaginateResponse";
import { ModuleStoreRequest } from "@/models/module/ModuleStoreRequest";
import messages from "@/constants/messages";

@Options({
  components: {},
})
export default class ModuleCreateView extends Vue {
  public rules = [(value: any) => !!value || "Required.", (value: string) => (value && value.length >= 3) || "Min 3 characters"];
  public nome = "";
  public messages = {};
  public description = "";
  public sortSelected = "name";
  public isLoadingTable = true;
  public needleSearch = null;
  public id: any = 0;
  public snackbar = false;
  public text = "";
  public timeout = 10000;
  public btn_disabled = false;
  public channels = [];
  public channelsIds = [];
  $route: any;

  mounted(): void {
    this.id = Number(this.$route.params.id);
    this.getModule(this.id);
    this.getChannels();
    this.messages = messages;
  }
  async getChannels() {
    const response = await services.ChannelService.getAllChannelsWithoutPaginate();
    this.channels = response.results;
  }
  changeLoading(): void {
    this.isLoadingTable = !this.isLoadingTable;
  }

  closeSnack(): void {
    this.snackbar = false;
    this.$router.push("/modules");
  }

  async update(event: any): Promise<void> {
    const storeRequest: ModuleStoreRequest = {
      name: this.nome,
      channels: this.channelsIds,
      description: this.description,
    };

    const response = await services.ModuleService.updateModule(storeRequest, this.id);

    if (response.has_success) {
      this.text = messages.success;
      this.snackbar = true;
      this.btn_disabled = true;
    } else {
      this.snackbar = true;
      this.text = messages.fail;
    }
  }

  async getModule(moduleId: number) {
    try {
      const response = await services.ModuleService.getModule(moduleId);

      this.nome = response.results.name;
      this.description = response.results.description;
      this.channelsIds = response.results.channels;
    } catch (e) {
      console.log("ERRO", e);
    }
    this.changeLoading();
  }
}
</script>
<style lang="css">
.default-data-base {
  border: solid #efecec 0.4px;
}

.w-3 {
  width: 10px;
}

.h-3 {
  height: 10px;
}
</style>
@/models/module/ModuleIndexWithPaginateResponse
