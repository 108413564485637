import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_autocomplete = _resolveComponent("v-autocomplete")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createBlock(_component_v_row, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_col, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_autocomplete, {
            modelValue: _ctx.privateFilters.status,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.privateFilters.status) = $event)),
            label: "Status",
            clearable: true,
            items: _ctx.allStatus,
            "item-title": "Status",
            "item-value": "Status",
            variant: "outlined",
            density: "compact",
            multiple: "",
            "error-messages": _ctx.error.status
          }, {
            selection: _withCtx((data) => [
              (_ctx.privateFilters.status.length < 2)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(data.item.title.slice(0, 7) + "..."), 1))
                : (data.index === 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(`${_ctx.privateFilters.status.length} status`), 1))
                  : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["modelValue", "items", "error-messages"])
        ]),
        _: 1
      }),
      _createVNode(_component_v_col, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_select, {
            modelValue: _ctx.privateFilters.type,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.privateFilters.type) = $event)),
            clearable: true,
            items: _ctx.allTypes,
            label: "Tipo",
            variant: "outlined",
            density: "compact",
            "error-messages": _ctx.error.type
          }, null, 8, ["modelValue", "items", "error-messages"])
        ]),
        _: 1
      }),
      _createVNode(_component_v_col, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_select, {
            modelValue: _ctx.privateFilters.modality,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.privateFilters.modality) = $event)),
            clearable: true,
            items: _ctx.allModalities,
            label: "Modalidade",
            variant: "outlined",
            density: "compact",
            "error-messages": _ctx.error.modality
          }, null, 8, ["modelValue", "items", "error-messages"])
        ]),
        _: 1
      }),
      _createVNode(_component_v_col, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_select, {
            modelValue: _ctx.privateFilters.options,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.privateFilters.options) = $event)),
            items: _ctx.options,
            clearable: true,
            label: "Opções",
            variant: "outlined",
            density: "compact",
            multiple: "",
            "error-messages": _ctx.error.options
          }, {
            selection: _withCtx((data) => [
              (_ctx.privateFilters.options.length < 2)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(data.item.title.slice(0, 7) + "..."), 1))
                : (data.index === 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(`${_ctx.privateFilters.options.length} opções`), 1))
                  : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["modelValue", "items", "error-messages"])
        ]),
        _: 1
      }),
      _createVNode(_component_v_col, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_text_field, {
            modelValue: _ctx.privateFilters.startDate,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.privateFilters.startDate) = $event)),
            label: "Data Inicio",
            type: "date",
            variant: "outlined",
            density: "compact",
            min: _ctx.min,
            max: _ctx.max,
            "error-messages": _ctx.error.startDate
          }, null, 8, ["modelValue", "min", "max", "error-messages"])
        ]),
        _: 1
      }),
      _createVNode(_component_v_col, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_text_field, {
            modelValue: _ctx.privateFilters.endDate,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.privateFilters.endDate) = $event)),
            label: "Data Fim",
            type: "date",
            variant: "outlined",
            density: "compact",
            min: _ctx.min,
            max: _ctx.max,
            "error-messages": _ctx.error.endDate
          }, null, 8, ["modelValue", "min", "max", "error-messages"])
        ]),
        _: 1
      }),
      _createVNode(_component_v_col, { class: "filters-buttons" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_btn, {
            color: "movida-orange",
            class: "text-white",
            onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.search())),
            "append-icon": "mdi-magnify"
          }, {
            default: _withCtx(() => _cache[8] || (_cache[8] = [
              _createTextVNode(" Buscar ")
            ])),
            _: 1
          }),
          _createVNode(_component_v_btn, {
            color: "movida-orange",
            class: "text-white",
            onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.clear())),
            "append-icon": "mdi-filter-remove"
          }, {
            default: _withCtx(() => _cache[9] || (_cache[9] = [
              _createTextVNode(" Limpar filtros ")
            ])),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}