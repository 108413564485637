<template>
  <div class="d-flex justify-space-between">
    <h1 class="">Atualizar Rota</h1>
    <a class="text-decoration-none">
      <v-btn class="text-white" color="grey" href="/routes">
        <v-icon> mdi-plus </v-icon>
        Cancelar
      </v-btn>
      <v-btn class="text-white" color="movida-orange" @click="update" v-bind:disabled="btn_disabled">
        <v-icon> mdi-plus </v-icon>
        Salvar
      </v-btn>
    </a>
  </div>
  <div>
    <div>
      <v-col cols="12" md="6">
        <v-text-field v-model="name" label="Nome" hide-details="auto"></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field v-model="description" label="Descrição" hide-details="auto"></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-select v-model="method" label="Método" :items="methodos" required item-title="name" item-value="id" persistent-hint @input="setSelected($event)"></v-select>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field v-model="path" label="Path" hide-details="auto"></v-text-field>
      </v-col>
    </div>
  </div>
  <v-snackbar v-model="snackbar" :timeout="timeout">
    <v-icon class="ml-1"> mdi-information-outline </v-icon> {{ text }}
    <template v-slot:actions>
      <v-btn variant="text" @click="closeSnack"> Fechar </v-btn>
    </template>
  </v-snackbar>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import * as services from "@/services";
import { RouteStoreRequest } from "@/models/route/RouteStoreRequest";
import messages from "@/constants/messages";

@Options({
  components: {},
})
export default class RoutesUpdateView extends Vue {
  public rules = [(value: any) => !!value || "Required.", (value: string) => (value && value.length >= 3) || "Min 3 characters"];
  public nome = "";
  public description = "";
  public method = "";
  public path = "";
  public sortSelected = "name";
  public isLoadingTable = true;
  public needleSearch = null;
  public id: any = 0;
  public snackbar = false;
  public text = "";
  public timeout = 10000;
  public methods = ["GET", "POST", "PUT", "DELETE"];
  public messages = {};
  public btn_disabled = false;
  $route: any;

  mounted(): void {
    this.id = Number(this.$route.params.id);
    this.getPath(this.id);
    this.messages = messages;
  }

  closeSnack(): void {
    this.snackbar = false;
    this.$router.push("/routes");
  }

  changeLoading(): void {
    this.isLoadingTable = !this.isLoadingTable;
  }

  async update(event: any): Promise<void> {
    const storeRequest: RouteStoreRequest = {
      name: this.nome,
      description: this.description,
      method_route: this.method,
      path: this.path,
      isEnable: false,
    };

    const response = await services.RouteService.updateRoute(storeRequest, this.id);

    if (response.has_success) {
      this.text = messages.success;
      this.snackbar = true;
      this.btn_disabled = true;
    } else {
      this.snackbar = true;
      this.text = messages.fail;
    }
  }

  async getPath(moduleId: number) {
    try {
      const response = await services.RouteService.getRoute(moduleId);

      this.nome = response.results.name;
      this.description = response.results.description;
      this.method = response.results.method;
      this.path = response.results.path;
    } catch (e) {
      console.log("ERRO", e);
    }
    this.changeLoading();
  }
}
</script>
<style lang="css">
.default-data-base {
  border: solid #efecec 0.4px;
}

.w-3 {
  width: 10px;
}

.h-3 {
  height: 10px;
}
</style>
@/models/module/ModuleIndexWithPaginateResponse
