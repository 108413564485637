import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, mergeProps as _mergeProps, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex justify-space-between" }
const _hoisted_2 = { class: "d-flex align-center gap-4 mr-6" }
const _hoisted_3 = { class: "d-flex justify-end" }
const _hoisted_4 = { class: "d-flex gap-4 mr-12" }
const _hoisted_5 = { class: "d-flex justify-end" }
const _hoisted_6 = { class: "d-flex gap-4 mr-6" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_data_table_server = _resolveComponent("v-data-table-server")!
  const _component_v_pagination = _resolveComponent("v-pagination")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[15] || (_cache[15] = _createElementVNode("div", { class: "d-flex justify-space-between mb-4" }, [
      _createElementVNode("h1", { class: "neutral-900" }, "Catálogo")
    ], -1)),
    _createVNode(_component_v_row, { class: "mb-3 flex justify-between" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, {
          md: "2",
          sm: "2"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_text_field, {
              modelValue: _ctx.needleSearch,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.needleSearch) = $event)),
              clearable: "",
              density: "compact",
              "hide-details": "",
              label: "Buscar...",
              "prepend-inner-icon": "mdi-magnify",
              "single-line": "",
              variant: "solo",
              onInput: _ctx.searchByNeedle,
              "onClick:clear": _ctx.clearFilter
            }, null, 8, ["modelValue", "onInput", "onClick:clear"])
          ]),
          _: 1
        }),
        _createVNode(_component_v_col, {
          md: "7",
          sm: "6"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_v_menu, { transition: "slide-y-transition" }, {
                  activator: _withCtx(({ props }) => [
                    _createVNode(_component_v_btn, _mergeProps({ color: "white" }, props), {
                      default: _withCtx(() => [
                        _createVNode(_component_v_icon, null, {
                          default: _withCtx(() => _cache[4] || (_cache[4] = [
                            _createTextVNode(" mdi-filter-variant")
                          ])),
                          _: 1
                        }),
                        _cache[5] || (_cache[5] = _createTextVNode(" Filtrar "))
                      ]),
                      _: 2
                    }, 1040)
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_v_list, null, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headersOrdenar, (item, i) => {
                          return (_openBlock(), _createBlock(_component_v_list_item, {
                            key: i,
                            value: i
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_list_item_title, {
                                onClick: ($event: any) => (_ctx.changeSortSelected(item.key))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(item.title), 1)
                                ]),
                                _: 2
                              }, 1032, ["onClick"])
                            ]),
                            _: 2
                          }, 1032, ["value"]))
                        }), 128))
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ])
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_v_col, {
          md: "1",
          sm: "6"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_v_menu, { transition: "slide-y-transition" }, {
                  activator: _withCtx(({ props }) => [
                    _createVNode(_component_v_btn, _mergeProps(props, {
                      class: "bg-red rounded-lg",
                      title: "Botão para limpeza de todos os grupos.",
                      onClick: _ctx.clearCacheGroups
                    }), {
                      default: _withCtx(() => _cache[6] || (_cache[6] = [
                        _createTextVNode(" Limpar cache ")
                      ])),
                      _: 2
                    }, 1040, ["onClick"])
                  ]),
                  _: 1
                })
              ])
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_v_col, {
          md: "2",
          sm: "6"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_v_menu, { transition: "slide-y-transition" }, {
                  activator: _withCtx(({ props }) => [
                    _createVNode(_component_v_btn, _mergeProps({ color: "white" }, props), {
                      default: _withCtx(() => [
                        _cache[8] || (_cache[8] = _createTextVNode(" Ordenar lista ")),
                        _createVNode(_component_v_icon, {
                          class: "ml-1",
                          dark: "",
                          left: ""
                        }, {
                          default: _withCtx(() => _cache[7] || (_cache[7] = [
                            _createTextVNode(" mdi-chevron-down")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 2
                    }, 1040)
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_v_list, null, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headersOrdenar, (item, i) => {
                          return (_openBlock(), _createBlock(_component_v_list_item, {
                            key: i,
                            value: i
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_list_item_title, {
                                onClick: ($event: any) => (_ctx.changeSortSelected(item.key))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(item.title), 1)
                                ]),
                                _: 2
                              }, 1032, ["onClick"])
                            ]),
                            _: 2
                          }, 1032, ["value"]))
                        }), 128))
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_v_data_table_server, {
      "items-per-page": _ctx.itemsPerPage,
      "onUpdate:itemsPerPage": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.itemsPerPage) = $event)),
      headers: _ctx.headers,
      items: _ctx.items,
      loading: _ctx.isLoadingTable,
      class: "elevation-0 rounded-lg",
      "hide-default-footer": "",
      "item-value": "name",
      to: "asd"
    }, {
      bottom: _withCtx(() => _cache[9] || (_cache[9] = [])),
      [`item.name`]: _withCtx(({ item }) => [
        _createVNode(_component_v_img, {
          style: {"width":"82px","height":"46px"},
          src: 'https://static.seminovosmovida.com.br/storage_dev/veiculo/1570719/729236_aff4d8519acd6cdd3decd279ef143cee.webp',
          onClick: ($event: any) => (_ctx.goToCatalog(item))
        }, null, 8, ["onClick"])
      ]),
      [`item.description`]: _withCtx(({ item }) => [
        _createElementVNode("tr", {
          class: "clickable",
          onClick: ($event: any) => (_ctx.goToCatalog(item))
        }, [
          _createVNode(_component_v_row, null, {
            default: _withCtx(() => [
              _createElementVNode("p", null, _toDisplayString(item.props.title.Grupo), 1)
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_v_row, null, {
            default: _withCtx(() => [
              _createElementVNode("p", null, _toDisplayString(item.props.title.GrupoID) + " • " + _toDisplayString(item.props.title.SimuladorAMPF_CodigoFIPE), 1)
            ]),
            _: 2
          }, 1024)
        ], 8, _hoisted_7)
      ]),
      [`item.options`]: _withCtx(({ item }) => [
        _createVNode(_component_v_menu, {
          bottom: "",
          left: ""
        }, {
          activator: _withCtx(({ props }) => [
            _createVNode(_component_v_btn, _mergeProps({
              dark: "",
              icon: "",
              style: {"box-shadow":"none"}
            }, props), {
              default: _withCtx(() => [
                _createVNode(_component_v_icon, null, {
                  default: _withCtx(() => _cache[10] || (_cache[10] = [
                    _createTextVNode("mdi-dots-vertical")
                  ])),
                  _: 1
                })
              ]),
              _: 2
            }, 1040)
          ]),
          default: _withCtx(() => [
            _createVNode(_component_v_list, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_list_item, {
                  onClick: ($event: any) => (_ctx.goToCatalog(item))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_list_item_title, null, {
                      default: _withCtx(() => _cache[11] || (_cache[11] = [
                        _createTextVNode("Editar")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 2
                }, 1032, ["onClick"]),
                _createVNode(_component_v_list_item, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_list_item_title, { style: {"color":"red"} }, {
                      default: _withCtx(() => _cache[12] || (_cache[12] = [
                        _createTextVNode("Desabilitar encomendas")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1024)
      ]),
      _: 2
    }, 1032, ["items-per-page", "headers", "items", "loading"]),
    _createVNode(_component_v_row, {
      class: "mt-2 d-flex align-center",
      justify: "center"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_v_pagination, {
            modelValue: _ctx.currentPage,
            "onUpdate:modelValue": [
              _cache[2] || (_cache[2] = ($event: any) => ((_ctx.currentPage) = $event)),
              _ctx.loadPage
            ],
            length: _ctx.numberOfPages,
            "total-visible": 7,
            color: "movida-orange",
            onNext: _ctx.nextPage,
            onPrev: _ctx.prevPage
          }, null, 8, ["modelValue", "length", "onNext", "onPrev", "onUpdate:modelValue"]),
          _createElementVNode("div", null, _toDisplayString(_ctx.pageFrom) + " a " + _toDisplayString(_ctx.pageTo) + " de " + _toDisplayString(_ctx.totalOfRows) + " resultados", 1)
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_v_snackbar, {
      modelValue: _ctx.snackbar,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.snackbar) = $event)),
      timeout: _ctx.timeout
    }, {
      actions: _withCtx(() => [
        _createVNode(_component_v_btn, {
          variant: "text",
          onClick: _ctx.closeSnack
        }, {
          default: _withCtx(() => _cache[14] || (_cache[14] = [
            _createTextVNode(" Fechar")
          ])),
          _: 1
        }, 8, ["onClick"])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_v_icon, { class: "ml-1" }, {
          default: _withCtx(() => _cache[13] || (_cache[13] = [
            _createTextVNode(" mdi-information-outline")
          ])),
          _: 1
        }),
        _createTextVNode(" " + _toDisplayString(_ctx.text) + " ", 1)
      ]),
      _: 1
    }, 8, ["modelValue", "timeout"])
  ], 64))
}