import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "d-flex justify-space-between" }
const _hoisted_2 = {
  href: "/routes/store",
  class: "text-decoration-none"
}
const _hoisted_3 = { class: "d-flex justify-space-between" }
const _hoisted_4 = { class: "d-flex align-center gap-4 mr-6" }
const _hoisted_5 = { class: "text-sm-body-2" }
const _hoisted_6 = ["href"]
const _hoisted_7 = { class: "text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_data_table_server = _resolveComponent("v-data-table-server")!
  const _component_v_pagination = _resolveComponent("v-pagination")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[5] || (_cache[5] = _createElementVNode("h1", { class: "" }, "Rotas", -1)),
      _createElementVNode("a", _hoisted_2, [
        _createVNode(_component_v_btn, {
          class: "text-white",
          color: "movida-orange"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_icon, null, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode(" mdi-plus ")
              ])),
              _: 1
            }),
            _cache[4] || (_cache[4] = _createTextVNode(" Nova Rota "))
          ]),
          _: 1
        })
      ])
    ]),
    _createVNode(_component_v_row, { class: "mb-3 flex justify-between" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, {
          sm: "3",
          md: "4"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_text_field, {
              label: "Buscar...",
              "prepend-inner-icon": "mdi-magnify",
              clearable: "",
              "single-line": "",
              density: "compact",
              variant: "solo",
              "hide-details": "",
              modelValue: _ctx.needleSearch,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.needleSearch) = $event)),
              onInput: _ctx.searchByNeedle,
              "onClick:clear": _ctx.clearFilter
            }, null, 8, ["modelValue", "onInput", "onClick:clear"])
          ]),
          _: 1
        }),
        _createVNode(_component_v_col, {
          sm: "3",
          md: "2"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_v_menu, { transition: "slide-y-transition" }, {
                  activator: _withCtx(({ props }) => [
                    _createVNode(_component_v_btn, _mergeProps({ color: "white" }, props), {
                      default: _withCtx(() => [
                        _cache[7] || (_cache[7] = _createTextVNode(" Ordenar por: ")),
                        _createVNode(_component_v_icon, {
                          left: "",
                          dark: "",
                          class: "ml-1"
                        }, {
                          default: _withCtx(() => _cache[6] || (_cache[6] = [
                            _createTextVNode(" mdi-chevron-down ")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 2
                    }, 1040)
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_v_list, null, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headersOrdenar, (item, i) => {
                          return (_openBlock(), _createBlock(_component_v_list_item, {
                            key: i,
                            value: i
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_list_item_title, {
                                onClick: ($event: any) => (_ctx.changeSortSelected(item.key))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(item.title), 1)
                                ]),
                                _: 2
                              }, 1032, ["onClick"])
                            ]),
                            _: 2
                          }, 1032, ["value"]))
                        }), 128))
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_v_data_table_server, {
      "items-per-page": _ctx.itemsPerPage,
      "onUpdate:itemsPerPage": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.itemsPerPage) = $event)),
      headers: _ctx.headers,
      items: _ctx.items,
      loading: _ctx.isLoadingTable,
      "item-value": "name",
      class: "elevation-1",
      "hide-default-footer": ""
    }, {
      bottom: _withCtx(() => _cache[8] || (_cache[8] = [])),
      [`item.status`]: _withCtx(({ item }) => [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("span", {
            class: _normalizeClass([{ 'bg-red': !!item.props.title.isEnabled, 'bg-green': !!item.props.title.isEnabled }, "w-3 h-3 rounded-circle d-inline-block mr-2"])
          }, null, 2),
          _createTextVNode(_toDisplayString(!!item.props.title.isEnabled ? "Ativo" : "Inativo"), 1)
        ])
      ]),
      [`item.options`]: _withCtx(({ item }) => [
        _createElementVNode("a", {
          href: '/routes/update/' + item.props.title.id,
          class: "text-decoration-none"
        }, "Editar", 8, _hoisted_6),
        _createVNode(_component_v_menu, {
          bottom: "",
          left: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_list, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_list_item, {
                  href: '/routes/update/' + item.props.title.id
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_list_item_title, null, {
                      default: _withCtx(() => _cache[9] || (_cache[9] = [
                        _createTextVNode("Editar")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 2
                }, 1032, ["href"])
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1024)
      ]),
      _: 2
    }, 1032, ["items-per-page", "headers", "items", "loading"]),
    _createVNode(_component_v_row, {
      class: "mt-2 d-flex align-center",
      justify: "center"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_v_pagination, {
            modelValue: _ctx.currentPage,
            "onUpdate:modelValue": [
              _cache[2] || (_cache[2] = ($event: any) => ((_ctx.currentPage) = $event)),
              _ctx.loadPage
            ],
            length: _ctx.numberOfPages,
            "total-visible": 7,
            onNext: _ctx.nextPage,
            onPrev: _ctx.prevPage,
            color: "movida-orange"
          }, null, 8, ["modelValue", "length", "onNext", "onPrev", "onUpdate:modelValue"]),
          _createElementVNode("div", null, _toDisplayString(_ctx.pageFrom) + " a " + _toDisplayString(_ctx.pageTo) + " de " + _toDisplayString(_ctx.totalOfRows) + " resultados ", 1)
        ])
      ]),
      _: 1
    })
  ], 64))
}