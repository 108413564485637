import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex justify-space-between" }
const _hoisted_2 = { class: "text-decoration-none" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_autocomplete = _resolveComponent("v-autocomplete")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_VueDatePicker = _resolveComponent("VueDatePicker")!
  const _component_v_progress_linear = _resolveComponent("v-progress-linear")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[8] || (_cache[8] = _createElementVNode("h1", { class: "" }, "Editar agendamentos", -1)),
      _createElementVNode("a", _hoisted_2, [
        _createVNode(_component_v_btn, {
          class: "text-white",
          color: "grey",
          href: "/leadtime#schedules"
        }, {
          default: _withCtx(() => _cache[6] || (_cache[6] = [
            _createTextVNode("Cancelar")
          ])),
          _: 1
        }),
        _createVNode(_component_v_btn, {
          class: "text-white",
          color: "movida-orange",
          onClick: _ctx.update,
          disabled: _ctx.btn_disabled
        }, {
          default: _withCtx(() => _cache[7] || (_cache[7] = [
            _createTextVNode("Salvar")
          ])),
          _: 1
        }, 8, ["onClick", "disabled"])
      ])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_v_col, {
        sm: "12",
        md: "6"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_text_field, {
            modelValue: _ctx.orderId,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.orderId) = $event)),
            density: "comfortable",
            label: "Pedido",
            rules: _ctx.rulesRequired,
            loading: _ctx.loading || _ctx.loadingOrder,
            variant: "solo",
            "append-inner-icon": "mdi-magnify",
            disabled: true
          }, null, 8, ["modelValue", "rules", "loading"])
        ]),
        _: 1
      }),
      _createVNode(_component_v_col, {
        sm: "12",
        md: "6"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_autocomplete, {
            modelValue: _ctx.branchId,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.branchId) = $event)),
            label: "Loja de Retirada",
            items: _ctx.branchs,
            rules: _ctx.rulesRequired,
            loading: _ctx.loading || _ctx.loadingBranch,
            "item-title": "nome",
            "item-value": "id",
            variant: "solo",
            disabled: true
          }, null, 8, ["modelValue", "items", "rules", "loading"])
        ]),
        _: 1
      }),
      _createVNode(_component_v_col, {
        sm: "12",
        md: "6"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_autocomplete, {
            modelValue: _ctx.serviceId,
            "onUpdate:modelValue": [
              _cache[2] || (_cache[2] = ($event: any) => ((_ctx.serviceId) = $event)),
              _ctx.getAvailability
            ],
            label: "Tipo de Agendamento",
            rules: _ctx.rulesRequired,
            loading: _ctx.loading || _ctx.loadingService,
            items: _ctx.services,
            "item-title": "nome",
            "item-value": "servico_id",
            variant: "solo"
          }, null, 8, ["modelValue", "rules", "loading", "items", "onUpdate:modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_v_col, {
        sm: "12",
        md: "6"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_divider)
        ]),
        _: 1
      }),
      _createVNode(_component_v_col, {
        sm: "12",
        md: "6"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_VueDatePicker, {
            modelValue: _ctx.date,
            "onUpdate:modelValue": [
              _cache[3] || (_cache[3] = ($event: any) => ((_ctx.date) = $event)),
              _ctx.getHourAvailability
            ],
            "input-class-name": "datepickerForm",
            locale: "pt-br",
            "select-text": "selecionar",
            "cancel-text": "cancelar",
            format: _ctx.format,
            "enable-time-picker": false,
            "allowed-dates": _ctx.allowedDates,
            onCleared: _ctx.clearCalendar
          }, null, 8, ["modelValue", "format", "allowed-dates", "onCleared", "onUpdate:modelValue"]),
          (_ctx.loading || _ctx.loadingDate)
            ? (_openBlock(), _createBlock(_component_v_progress_linear, {
                key: 0,
                indeterminate: ""
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_v_col, {
        sm: "12",
        md: "6"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_select, {
            modelValue: _ctx.hour,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.hour) = $event)),
            items: _ctx.allowedHour,
            rules: _ctx.rulesRequired,
            loading: _ctx.loading || _ctx.loadingHour,
            variant: "solo",
            "append-inner-icon": "mdi-clock"
          }, null, 8, ["modelValue", "items", "rules", "loading"])
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_v_snackbar, {
      modelValue: _ctx.snackbar,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.snackbar) = $event)),
      timeout: _ctx.timeout
    }, {
      actions: _withCtx(() => [
        _createVNode(_component_v_btn, {
          variant: "text",
          onClick: _ctx.closeSnack
        }, {
          default: _withCtx(() => _cache[10] || (_cache[10] = [
            _createTextVNode(" Fechar ")
          ])),
          _: 1
        }, 8, ["onClick"])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_v_icon, { class: "ml-1" }, {
          default: _withCtx(() => _cache[9] || (_cache[9] = [
            _createTextVNode(" mdi-information-outline ")
          ])),
          _: 1
        }),
        _createTextVNode(" " + _toDisplayString(_ctx.text) + " ", 1)
      ]),
      _: 1
    }, 8, ["modelValue", "timeout"])
  ], 64))
}