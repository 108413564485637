<script lang="ts">
import { Options, Vue } from "vue-class-component";
import * as services from "@/services";
import moment from "moment";
import { Deadlines } from "@/models/deadlines/Deadlines";

@Options({})
export default class DeadlinesIndexView extends Vue {
  public loading = false;
  public items: Array<Deadlines> = [];
  public currentPage = 0;
  public numberOfPages = 0;
  public itemsPerPage = 0;
  public totalOfRows = 0;
  public pageFrom = 0;
  public pageTo = 1;
  public headers = [
    { title: "Marca", align: "start", key: "brand_name", sortable: false },
    { title: "Faturamento", align: "center", key: "billing_deadline", sortable: false },
    { title: "Implantação", align: "center", key: "implementation_deadline", sortable: false },
    { title: "Operacional", align: "center", key: "operational_deadline", sortable: false },
    { title: "Atualizado em", align: "end", key: "updated_at", sortable: false },
    { title: "Ações", align: "end", key: "options", sortable: false },
  ];
  public confirmDialog = false;
  private deleteId: number | null = null;
  public snackbar = false;
  public snackbarText = "";
  public snackbarColor = "";

  mounted(): void {
    this.buildTable();
  }

  async buildTable(): Promise<void> {
    this.loading = true;
    await this.getAll(this.currentPage);
    this.loading = false;
  }

  async getAll(page: number): Promise<void> {
    const response = await services.DeadlinesService.getAll(page);
    if (response.results) {
      this.currentPage = response.results.current_page;
      this.items = response.results.data as Array<Deadlines>;
      this.numberOfPages = response.results.last_page;
      this.itemsPerPage = response.results.per_page;
      this.totalOfRows = response.results.total;
      this.pageFrom = response.results.current_page;
      this.pageTo = response.results.per_page;
    }
  }

  nextPage(): void {
    this.buildTable();
  }

  prevPage(): void {
    this.buildTable();
  }

  loadPage(value: number): void {
    this.currentPage = value;
    this.buildTable();
  }

  format_date(value: any) {
    if (value) {
      return moment(String(value)).format("DD/MM/YYYY HH:mm");
    }
  }

  add() {
    this.$router.push({ path: "/deadlines/store" });
  }

  async remove(id: number): Promise<void> {
    this.showConfirmDialog(id);
  }

  public showConfirmDialog(id: number) {
    this.deleteId = id;
    this.confirmDialog = true;
  }

  public async confirmDelete(): Promise<void> {
    if (this.deleteId !== null) {
      this.loading = true;
      try {
        const response = await services.DeadlinesService.destroy(this.deleteId);
        this.snackbarText = response.message;
        this.snackbarColor = "success";
        this.snackbar = true;
        this.buildTable();
      } catch (error: any) {
        this.snackbarText = error.message;
        this.snackbarColor = "danger";
        this.snackbar = true;
      } finally {
        this.confirmDialog = false;
        this.deleteId = null;
        this.loading = false;
      }
    }
  }
}
</script>

<template>
  <v-row>
    <v-col>
      <h1 class="text-h4">Prazo de Retirada</h1>
    </v-col>
    <v-col>
      <v-btn class="bg-red rounded-lg float-right" href="/deadlines/store">Adicionar</v-btn>
    </v-col>
  </v-row>
  <v-row>
    <v-col>
      <v-data-table-server v-model:items-per-page="itemsPerPage" :headers="headers" :items="items" :loading="loading" item-value="name" class="elevation-1" hide-default-footer>
        <template v-slot:loading>
          <v-sheet class="d-flex align-center text-center justify-center pt-3 pb-3">
            <v-chip> Carregando... </v-chip>
          </v-sheet>
        </template>
        <template v-slot:bottom></template>
        <template v-slot:[`item.updated_at`]="{ item }">
          {{ format_date(item.value.updated_at) }}
        </template>
        <template v-slot:[`item.options`]="{ item }">
          <v-menu bottom left>
            <template v-slot:activator="{ on, props }">
              <v-btn dark icon v-bind="props" v-on="on" style="box-shadow: none">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item :href="'/deadlines/update/' + item.value.id">
                <v-list-item-title>Editar</v-list-item-title>
              </v-list-item>
              <v-list-item @click="remove(item.value.id)">
                <v-list-item-title>Remover</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table-server>
      <v-row class="mt-2 d-flex align-center" justify="center">
        <div class="text-center">
          <v-pagination v-model="currentPage" :length="numberOfPages" :total-visible="7" color="orange" @next="nextPage" @prev="prevPage" @update:modelValue="loadPage"></v-pagination>
          <div> {{ pageFrom }} a {{ pageTo }} de {{ totalOfRows }} resultados</div>
        </div>
      </v-row>
    </v-col>
  </v-row>
  <v-container>
    <v-dialog v-model="confirmDialog" max-width="500px">
      <v-card>
        <v-card-title class="headline mt-2">Confirme a remoção</v-card-title>
        <v-card-text>Você tem certeza que deseja remover este item?</v-card-text>
        <v-divider class="mt-2 pb-4"></v-divider>
        <v-card-actions class="pb-4">
          <v-spacer></v-spacer>
          <v-btn text @click="confirmDialog = false">Cancelar</v-btn>
          <v-btn class="bg-red rounded-lg" @click="confirmDelete">Remover Definitivamente</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" :timeout="3000" :color="snackbarColor">
      {{ snackbarText }}
      <v-btn color="white" text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
  </v-container>
</template>

<style scoped lang="scss"></style>
